import { useEffect, useRef, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import DirectoryPage from "./DirectoryPage";
import MemberDetailsPage from "./MemberDetails/MemberDetailsPage";
import NotFoundPage from "pages/Error/NotFoundPage";
function DirectoryRoutes(props) {
  let location = useLocation();

  const getSearchParamsValue = (searchParam) => {
    let searchParams = location.search.replace("?", "").split("&");

    let nameSearchParameter = "name";
    if (nameSearchParameter === searchParam) {
      return searchParams.find((param) => param.startsWith(searchParam))?.split("=")[1];
    }

    let booleanSearchParameters = ["has_ref_objects", "has_jobs", "is_specialist"];
    if (booleanSearchParameters.includes(searchParam)) {
      return searchParams.some((param) => param.startsWith(searchParam));
    }

    let tabIndexAndSortParameters = ["tab", "sort"];
    if (tabIndexAndSortParameters.includes(searchParam)) {
      return Number(searchParams.find((param) => param.startsWith(searchParam))?.split("=")[1]);
    }

    let parmaValue = searchParams
      .find((param) => param.startsWith(searchParam))
      ?.split("=")[1]
      ?.split(",")
      .map((e) => {
        let result = Number(e) || e;
        return result;
      });
    return parmaValue;
  };

  let { path } = useRouteMatch();
  const [selectedFilters, setSelectedFilters] = useState({
    name: getSearchParamsValue("name") || "",
    genders: getSearchParamsValue("genders") || [],
    cities: getSearchParamsValue("cities") || [],
    states: getSearchParamsValue("states") || [],
    countries: getSearchParamsValue("countries") || [],
    member_types: getSearchParamsValue("member_types") || [],
    sections: getSearchParamsValue("sections") || [],
    professional_groups: getSearchParamsValue("professional_groups") || [],
    work_specialisations: getSearchParamsValue("work_specialisations") || [],
    work_branches: getSearchParamsValue("work_branches") || [],
    sia_commissions: getSearchParamsValue("sia_commissions") || [],
    has_ref_objects: getSearchParamsValue("has_ref_objects") || false,
    has_jobs: getSearchParamsValue("has_jobs") || false,
    is_specialist: getSearchParamsValue("is_specialist") || false,
    specialists: getSearchParamsValue("specialists") || [],
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(getSearchParamsValue("tab") || 0);

  const [singleMembersPerpageLimit, setSingleMembersPerPageLimit] = useState(12);
  const [singleMembersTragetPage, setSingleMembersTragetPage] = useState(0);

  const [juniorMembersPerPageLimit, setJuniorPerPageLimit] = useState(12);
  const [juniorMembersTargetPage, setJuniorMembersTagrgetPage] = useState(0);

  const [honoraryMembersPerPageLimit, setHonoraryMembersPerPageLimit] = useState(12);
  const [honoraryMembersTargetPage, setHonoraryMembersTargetPage] = useState(0);

  const [seniorMembersPerPageLimit, setSeniorMembersPerPageLimit] = useState(12);
  const [seniorMembersTargetPage, setSeniorMembersTargetPage] = useState(0);

  const [studentMembersPerPageLimit, setStudentMembersPerPageLimit] = useState(12);
  const [studentMembersTargetPage, setStudentMembersTargetPage] = useState(0);

  const [corporateMembersPerPageLimit, setCorporateMembersPerPageLimit] = useState(12);
  const [corporateMembersTargetPage, setCorporateMembersTargetPage] = useState(0);

  const translate = useTranslator();

  const getSortLabel = (searchId) => {
    switch (searchId) {
      case 1:
        return translate("directory.sort.name.ascending");
      case 2:
        return translate("directory.sort.name.descending");
      case 3:
        return translate("directory.sort.location.ascending");
      case 4:
        return translate("directory.sort.location.descending");
      default:
        return "";
    }
  };

  const [chosenSortItem, setChosenSortItem] = useState({
    id: getSearchParamsValue("sort") || 1,
    label: getSortLabel(getSearchParamsValue("sort") || 1),
    sortMapping: getSearchParamsValue("sort") || 1,
  });

  const metaTagRef = useRef(null);

  useEffect(() => {
    let metaTag = document.createElement("meta");
    metaTag.setAttribute("name", "robots");
    metaTag.setAttribute("content", "noindex");
    metaTagRef.current = metaTag;
    document.head.append(metaTagRef.current);
    return () => {
      metaTagRef.current.remove();
    };
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}/member/:id`} render={() => <MemberDetailsPage setSelectedFilters={setSelectedFilters} />} />
      <Route
        // exact
        path={path}
        render={() => (
          <DirectoryPage
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            singleMembersPerpageLimit={singleMembersPerpageLimit}
            setSingleMembersPerPageLimit={setSingleMembersPerPageLimit}
            singleMembersTragetPage={singleMembersTragetPage}
            setSingleMembersTragetPage={setSingleMembersTragetPage}
            juniorMembersPerPageLimit={juniorMembersPerPageLimit}
            setJuniorPerPageLimit={setJuniorPerPageLimit}
            juniorMembersTargetPage={juniorMembersTargetPage}
            setJuniorMembersTagrgetPage={setJuniorMembersTagrgetPage}
            honoraryMembersPerPageLimit={honoraryMembersPerPageLimit}
            setHonoraryMembersPerPageLimit={setHonoraryMembersPerPageLimit}
            honoraryMembersTargetPage={honoraryMembersTargetPage}
            setHonoraryMembersTargetPage={setHonoraryMembersTargetPage}
            seniorMembersPerPageLimit={seniorMembersPerPageLimit}
            setSeniorMembersPerPageLimit={setSeniorMembersPerPageLimit}
            seniorMembersTargetPage={seniorMembersTargetPage}
            setSeniorMembersTargetPage={setSeniorMembersTargetPage}
            studentMembersPerPageLimit={studentMembersPerPageLimit}
            setStudentMembersPerPageLimit={setStudentMembersPerPageLimit}
            studentMembersTargetPage={studentMembersTargetPage}
            setStudentMembersTargetPage={setStudentMembersTargetPage}
            corporateMembersPerPageLimit={corporateMembersPerPageLimit}
            setCorporateMembersPerPageLimit={setCorporateMembersPerPageLimit}
            corporateMembersTargetPage={corporateMembersTargetPage}
            setCorporateMembersTargetPage={setCorporateMembersTargetPage}
            chosenSortItem={chosenSortItem}
            setChosenSortItem={setChosenSortItem}
          />
        )}
      />
      <Route path="*" render={() => <NotFoundPage />} />
    </Switch>
  );
}
export default DirectoryRoutes;
