import React, { useContext, useEffect, useState } from "react";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import { LogoLinkedin32, LogoXing32, Form, FormFields, InfoBlock, dateFormatter, Content } from "@sia/style-guide";
import { WebsiteInput } from "components/WebsiteInput";
import "./Formation.scss";
import Cloner from "common/Cloner";
import erpAdapter from "adapters/ERPAdapter";
import { BackButton } from "@sia/style-guide/dist/components/BackButton/BackButton";

const FormationPage = () => {
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const GlobalContextValue = useContext(GlobalContext);
  const user = GlobalContextValue.user;
  const language = GlobalContextValue.language;
  const [wasFormationFetchInitialized, setWasFormationFetchInitialized] = useState(false);
  const [wasWorkFetchInitialized, setWasWorkFetchInitialized] = useState(false);
  const [wasWorksBranchesFetchInitialized, setWasWorksBranchesFetchInitialized] = useState(false);
  const [wasWorksSpecializationsFetchInitialized, setWasWorksSpecializationsFetchInitialized] = useState(false);
  const [wasProfessionalNetworksFetchInitialized, setWasProfessionalNetworksFetchInitialized] = useState(false);
  const [formations, setFormations] = useState([]);
  const [works, setWorks] = useState([]);
  const [worksBranches, setWorksBranches] = useState({});
  const [worksSpecializations, setWorksSpecializations] = useState({});
  const [linkedin, setLinkedin] = useState("");
  const [linkedInInvalidText, setLinkedInInvalidText] = useState("");
  const [xing, setXing] = useState("");
  const [xingInvalidText, setXingInvalidText] = useState("");

  const title = translate("account.customer-data.formation");
  const translationKeys = {
    type: "account.customer-data.formation.formation.type",
    diplomaTitle: "account.customer-data.formation.formation.diploma-title",
    profession: "account.customer-data.formation.workplace.profession",
    company: "account.customer-data.formation.workplace.company",
    function: "account.customer-data.formation.workplace.function",
    branch: "account.customer-data.formation.workplace.branch",
    areaOfActivity: "account.customer-data.formation.workplace.area-of-activity",
  };

  useEffect(() => {
    if (user && !wasFormationFetchInitialized) {
      setWasFormationFetchInitialized(true);
      webPlatformAdapter.getFormationSingle(language, (response) => {
        const formations = response.data.map((rawFormation) => {
          return {
            type: rawFormation.type.name,
            diplomaTitle: rawFormation.diploma_title,
            from: rawFormation.from_date,
            to: rawFormation.to_date,
          };
        });
        setFormations(formations);
      });
    }
    if (user && !wasWorkFetchInitialized) {
      setWasWorkFetchInitialized(true);
      webPlatformAdapter.getWorkSingle(language, (response) => {
        const works = response.data.map((rawWork) => {
          return {
            id: rawWork.id,
            profession: rawWork.type?.name,
            from: rawWork.from_date,
            to: rawWork.to_date,
            company: rawWork.name,
            function: rawWork.function?.name,
          };
        });
        setWorks(works);
      });
    }
    if (user && works.length && !wasWorksBranchesFetchInitialized) {
      setWasWorksBranchesFetchInitialized(true);
      works.forEach((work) => {
        webPlatformAdapter.getWorkBranchesSingle(work.id, language, (response) => {
          const workBranches = response.data.map((workBranch) => workBranch.work_branch.name);
          const newWorksBranches = Cloner.clone(worksBranches);
          newWorksBranches[work.id] = workBranches;
          setWorksBranches(newWorksBranches);
        });
      });
    }
    if (user && works.length && !wasWorksSpecializationsFetchInitialized) {
      setWasWorksSpecializationsFetchInitialized(true);
      works.forEach((work) => {
        webPlatformAdapter.getWorkSpecializationsSingle(work.id, language, (response) => {
          const workSpecializations = response.data.map((workSpecialization) => workSpecialization.work_specialisation.name);
          const newWorksSpecializations = Cloner.clone(worksSpecializations);
          newWorksSpecializations[work.id] = workSpecializations;
          setWorksSpecializations(newWorksSpecializations);
        });
      });
    }
    if (user && !wasProfessionalNetworksFetchInitialized) {
      setWasProfessionalNetworksFetchInitialized(true);
      webPlatformAdapter.getProfessionalNetworksSingle(language, (response) => {
        const linkedin = response.data[0].linkedin;
        const xing = response.data[0].xing;
        if (linkedin) {
          setLinkedin(linkedin);
        }
        if (xing) {
          setXing(xing);
        }
      });
    }
  }, [
    user,
    wasFormationFetchInitialized,
    wasProfessionalNetworksFetchInitialized,
    wasWorkFetchInitialized,
    wasWorksBranchesFetchInitialized,
    works,
    worksBranches,
    wasWorksSpecializationsFetchInitialized,
    worksSpecializations,
    language,
  ]);

  // todo, optimize this workaround. see https://sia-webplattform.atlassian.net/browse/SW-243?focusedCommentId=10515
  const onSave = () => {
    setLinkedInInvalidText("");
    setXingInvalidText("");
    erpAdapter.request(
      "patch",
      "appAccountProfessionalNetworksSingle",
      { linkedin_profile: linkedin.trim().replace(/^https?:\/\//, "") },
      () => {
        erpAdapter.request(
          "patch",
          "appAccountProfessionalNetworksSingle",
          { xing_profile: xing.trim().replace(/^https?:\/\//, "") },
          () => null,
          () => setXingInvalidText(translate("api.error.value.invalid"))
        );
      },
      () => setLinkedInInvalidText(translate("api.error.value.invalid"))
    );
  };

  const renderFormations = () => {
    if (!formations.length) {
      return <p>{translate("data.no-items-found")}</p>;
    }
    return formations.map((formation, index) => (
      <InfoBlock key={index}>
        <span className={"label"}>{translate(translationKeys.type)}</span>
        <p>{formation.type}</p>

        <span className={"label"}>{translate(translationKeys.diplomaTitle)}</span>
        <p>{formation.diplomaTitle}</p>

        <span className={"label"}>{translate("date.start")}</span>
        <p>{dateFormatter.format(formation.from)}</p>

        <span className={"label"}>{translate("date.end")}</span>
        <p>{dateFormatter.format(formation.to)}</p>
      </InfoBlock>
    ));
  };
  const renderWorks = () => {
    if (!works.length) {
      return <p>{translate("data.no-items-found")}</p>;
    }
    return works.map((work, index) => {
      const workBranches = worksBranches[work.id];
      const workSpecializations = worksSpecializations[work.id];
      return (
        <InfoBlock key={index}>
          <span className={"label"}>{translate(translationKeys.company)}</span>
          <p>{work.company}</p>

          {work.profession && (
            <>
              <span className={"label"}>{translate(translationKeys.profession)}</span>
              <p>{work.profession}</p>
            </>
          )}
          {work.function && (
            <>
              <span className={"label"}>{translate(translationKeys.function)}</span>
              <p>{work.function}</p>
            </>
          )}
          {workBranches && (
            <>
              <span className={"label"}>{translate(translationKeys.branch)}</span>
              <p>{workBranches.join(", ")}</p>
            </>
          )}
          {workSpecializations && (
            <>
              <span className={"label"}>{translate(translationKeys.areaOfActivity)}</span>
              <p>{workSpecializations.join(", ")}</p>
            </>
          )}

          <span className={"label"}>{translate("date.from")}</span>
          <p>{dateFormatter.format(work.from)}</p>
        </InfoBlock>
      );
    });
  };
  const renderContent = () => {
    return (
      <>
        <h3>{translate("account.customer-data.formation.formation")}</h3>
        {renderFormations()}

        <h3>{translate("account.customer-data.formation.workplace")}</h3>
        {renderWorks()}

        <h3>{translate("account.customer-data.formation.professional-networks")}</h3>
        <Form primaryButtonLabel={translate("form.save")} onClickPrimaryButton={onSave}>
          <FormFields>
            <div className={"social-input"}>
              <LogoLinkedin32 />
              <WebsiteInput
                id={"linkedin"}
                label={translate("account.customer-data.formation.professional-networks.linkedin")}
                value={linkedin}
                onChange={(event, value, error) => {
                  setLinkedInInvalidText("");
                  setLinkedin(value);
                  if (error) {
                    setLinkedInInvalidText(error);
                  }
                }}
                invalidText={linkedInInvalidText}
              />
            </div>
            <div className={"social-input"}>
              <LogoXing32 />
              <WebsiteInput
                id={"xing"}
                label={translate("account.customer-data.formation.professional-networks.xing")}
                value={xing}
                onChange={(event, value, error) => {
                  setXingInvalidText("");
                  setXing(value);
                  if (error) {
                    setXingInvalidText(error);
                  }
                }}
                invalidText={xingInvalidText}
              />
            </div>
          </FormFields>
        </Form>
      </>
    );
  };

  return (
    <Page contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <Content width={"slim"} centeredTitle={true}>
        <h1>
          <BackButton onClick={() => history.push(pathFactory.create("account/customer-data"))} />
          {title}
        </h1>
        {renderContent()}
      </Content>
    </Page>
  );
};
export default FormationPage;
