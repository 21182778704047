import React, { useContext, useEffect, useRef } from "react";
import { useTranslator } from "common/Translation/Translator";
import { useHistory } from "react-router-dom";
import UserContentNavigation from "pages/Account/UserContentNavigation";
import Page from "pages/Page";
import GlobalContext from "contexts/GlobalContext";
import { usePathFactory } from "common/Path/PathFactoryHook";
import webPlatformAdapter from "common/Adapters/WebPlatformAdapter/WebPlatformAdapter";
import erpAdapter from "adapters/ERPAdapter";
import "./ManageDirectory.scss";
import useReducerWithMiddleWare from "common/Reducer";
import {
  Form,
  FormFieldsColumn,
  InfoBlock,
  Content,
  AppContent,
  BackButton,
  RadioButtons,
  Checkbox,
  TextArea,
  TagsGroup,
  phoneFormatter,
  Link,
  LogoLinkedin32,
  LogoXing32,
} from "@sia/style-guide";

function ManageDirectory() {
  const translate = useTranslator();
  const history = useHistory();
  const pathFactory = usePathFactory();
  const title = translate("account.customer-data.sia-directory.title");
  const { user, language } = useContext(GlobalContext);

  const CORPORATE_MEMBER_ROLE = "sia_role_member_corporate";
  const SINGLE_MEMBER_ROLE = "sia_role_member_single";

  const detailsRef = useRef(null);
  const textAreaRef = useRef(null);
  const membershipRef = useRef(null);

  const isUserCoporate = user.isMemberTypeGroupOf("corporate") && user.getRole() === CORPORATE_MEMBER_ROLE;
  const isUserSingle = user.isMemberTypeGroupOf("single") && user.getRole() === SINGLE_MEMBER_ROLE;

  const SELECTED_ADDRESS_OPTIONS_CHANGED_ACTION = "SELECTED_ADDRESS_OPTIONS_CHANGED_ACTION";
  const addressOptionsActionCreator = (option) => ({ type: SELECTED_ADDRESS_OPTIONS_CHANGED_ACTION, payload: option });

  const SELECTED_OTHER_OPTIONS_CHANGED_ACTION = "SELECTED_OTHER_OPTIONS_CHANGED_ACTION";
  const otherOptionsActionCreator = (option) => ({ type: SELECTED_OTHER_OPTIONS_CHANGED_ACTION, payload: option });

  const FURTHER_INFO_TEXT_CHANGED_ACTION = "FURTHER_INFO_TEXT_CHANGED_ACTION";
  const furtherInfoActionCreator = (text) => ({ type: FURTHER_INFO_TEXT_CHANGED_ACTION, payload: text });

  const WORK_BRANCHES_FETCHED_ACTION = "WORK_BRANCHES_FETCHED_ACTION";
  const workBranchesActionCreator = (branches) => ({ type: WORK_BRANCHES_FETCHED_ACTION, payload: branches });

  const WORK_SPECIALISATIONS_FETCHED_ACTION = "WORK_SPECIALISATION_FETCHED_ACTION";
  const workspecialisationsActionCreator = (specialisations) => ({ type: WORK_SPECIALISATIONS_FETCHED_ACTION, payload: specialisations });

  const PERSONAL_INFO_FETCHED_ACTION = "PERSONAL_INFO_FETCHED_ACTION";
  const personalInfoActionCreator = (personalInfo) => ({ type: PERSONAL_INFO_FETCHED_ACTION, payload: personalInfo });

  const SHOULD_FETCH_DATA_ACTION = "DATA_FETCHED_ACTION";
  const shouldFetchActionCreator = (shouldFetch) => ({ type: SHOULD_FETCH_DATA_ACTION, payload: shouldFetch });

  const SERVER_STATE_FETCHED_ACTION = "SERVER_STATE_INITIALISED_ACTION";
  const serverStateCreator = (state) => ({ type: SERVER_STATE_FETCHED_ACTION, payload: state });

  const reducer = (state, action) => {
    switch (action.type) {
      case SELECTED_ADDRESS_OPTIONS_CHANGED_ACTION:
        return { ...state, selectedAddressOptions: action.payload };

      case SELECTED_OTHER_OPTIONS_CHANGED_ACTION: {
        return {
          ...state,
          otherOptions: {
            ...state.otherOptions,
            [action.payload.key]: { label: action.payload.label, value: action.payload.value },
          },
        };
      }

      case FURTHER_INFO_TEXT_CHANGED_ACTION:
        return {
          ...state,
          furtherInfo: action.payload,
        };

      case WORK_BRANCHES_FETCHED_ACTION:
        return {
          ...state,
          workBranches: action.payload,
        };

      case WORK_SPECIALISATIONS_FETCHED_ACTION:
        return {
          ...state,
          workSpecialisations: action.payload,
        };

      case PERSONAL_INFO_FETCHED_ACTION:
        return {
          ...state,
          personalInfo: action.payload,
        };

      case SHOULD_FETCH_DATA_ACTION: {
        return {
          ...state,
          shouldFetchData: action.payload,
        };
      }

      case SERVER_STATE_FETCHED_ACTION: {
        return {
          ...state,
          serverState: action.payload,
        };
      }

      default:
        return state;
    }
  };
  let fullAddress = translate("account.customer-data.sia-directory.options.full-address");
  let cityOnly = translate("account.customer-data.sia-directory.options.only-city");
  let noAddress = translate("account.customer-data.sia-directory.options.no-address");

  let jobTitle = translate("account.customer-data.sia-directory.single.options.job-title");
  let telephoneSingle = translate("account.customer-data.sia-directory.single.options.telephone");
  let mobileSingle = translate("account.customer-data.sia-directory.single.options.mobile");
  let emailSingle = translate("account.customer-data.sia-directory.single.options.email");

  let telephone = translate("account.customer-data.sia-directory.corporate.options.telephone");
  let email = translate("account.customer-data.sia-directory.corporate.options.email");
  let website = translate("account.customer-data.sia-directory.corporate.options.website");

  const init = (user) => {
    const initialAddressOptions = {
      fullAddress: { label: fullAddress, value: fullAddress },
      cityOnly: { label: cityOnly, value: cityOnly },
      noAddress: { label: noAddress, value: noAddress },
    };

    const initialOtherOptionsSingle = {
      jobTitle: { label: jobTitle, value: false },
      telephoneSingle: { label: telephoneSingle, value: false },
      mobileSingle: { label: mobileSingle, value: false },
      emailSingle: { label: emailSingle, value: false },
    };

    const initialOtherOptionsCorporate = {
      telephone: { label: telephone, value: false },
      email: { label: email, value: false },
      website: { label: website, value: false },
    };

    const initialOtherOptions = user.isMemberTypeGroupOf("corporate") ? initialOtherOptionsCorporate : initialOtherOptionsSingle;

    let initialState = {
      serverState: null,
      addressOptions: { ...initialAddressOptions },
      otherOptions: { ...initialOtherOptions },
      workBranches: [],
      workSpecialisations: [],
      selectedAddressOptions: null,
      furtherInfo: "",
      shouldFetchData: true,
      personalInfo: {
        city: "",
        xing: null,
        linkedin: null,
        phone: "",
        mobile: "",
        email: "",
        website: "",
        addressLines: [],
        diplomaTitle: "",
        individualMemeberships: [],
        memberships: {},
        groupNameLabel: "",
        branchLabel: "",
        specialisationLabel: "",
      },
    };

    return initialState;
  };

  const [state, dispatch] = useReducerWithMiddleWare(reducer, user, init, [], []);

  useEffect(() => {
    if (state.shouldFetchData) {
      const handleResponse = (response) => {
        const responseOptions = response.data.directory_options[0];

        const responseFullAddress = responseOptions.full_address && fullAddress;
        const responseCityOnly = responseOptions.city_only && cityOnly;
        const responseNoAddress = responseOptions.no_address && noAddress;

        const selectedAddressOption = [responseFullAddress, responseCityOnly, responseNoAddress].find((option) => option);
        dispatch(addressOptionsActionCreator(selectedAddressOption));

        const responseDetails = response.data?.details ||
          response.data.work?.[0] || {
            work_type: "",
            work_branches: [{ name: "" }],
            _work_specialisations: [{ name: "" }],
          };
        const responseWorkBranches = responseDetails.work_branches.map((branch) => branch.name);
        dispatch(workBranchesActionCreator(responseWorkBranches));

        const responseWorkSpecialisations = (responseDetails["work_specialisations"] || responseDetails["_work_specialisations"]).map(
          (specialisation) => specialisation.name
        );
        dispatch(workspecialisationsActionCreator(responseWorkSpecialisations));

        const responseMemberships = response.data?.memberships;

        let responseCard = response.data.card?.card?.[0] || response.data.card;
        const isRespondCardArray = Array.isArray(responseCard);
        if (isRespondCardArray) {
          responseCard = responseCard[0];
        }
        const addressLines = Object.entries(responseCard).filter(([key, value]) => key.startsWith("line_") && value);
        const individualMemeberships = groupByKey(responseDetails.ogl, "group_name");

        const personalInfo = {
          name: responseCard.name,
          city: responseCard.city,
          xing: responseCard.xing,
          linkedin: responseCard.linkedin,
          phone: responseCard.phone,
          mobile: responseCard.mobile,
          email: responseCard.email,
          addressLines: addressLines,
          diplomaTitle: responseCard.diploma_title,
          website: responseCard.website,
          individualMemeberships,
          memberships: responseMemberships,
          groupNameLabel: responseDetails?.ogl?.[0]?.group_name,
          branchLabel: responseDetails.work_branches[0].group_name,
          specialisationLabel: (responseDetails.work_specialisations || responseDetails._work_specialisations)[0].group_name,
        };
        dispatch(personalInfoActionCreator(personalInfo));
        if (isUserCoporate) {
          const responseTelephone = { key: "telephone", label: telephone, value: responseOptions.display_phone };
          dispatch(otherOptionsActionCreator(responseTelephone));

          const responseEmail = { key: "email", label: email, value: responseOptions.display_email };
          dispatch(otherOptionsActionCreator(responseEmail));

          const responseWebsite = { key: "website", label: website, value: responseOptions.display_website };
          dispatch(otherOptionsActionCreator(responseWebsite));

          const responseFurtherInfo = responseOptions.convert_html_to_text;
          dispatch(furtherInfoActionCreator(responseFurtherInfo));
          dispatch(
            serverStateCreator({
              selectedAddressOption,
              telephone: responseOptions.display_phone,
              email: responseOptions.display_email,
              website: responseOptions.display_website,
              furtherInfo: responseOptions.convert_html_to_text,
            })
          );
        }

        if (isUserSingle) {
          const responseJobTitle = { key: "jobTitle", label: jobTitle, value: responseOptions.display_diploma_title };
          dispatch(otherOptionsActionCreator(responseJobTitle));

          const responseTelephone = { key: "telephoneSingle", label: telephone, value: responseOptions.display_phone };
          dispatch(otherOptionsActionCreator(responseTelephone));

          const responseWebsite = { key: "mobileSingle", label: mobileSingle, value: responseOptions.display_mobile };
          dispatch(otherOptionsActionCreator(responseWebsite));

          const responseEmail = { key: "emailSingle", label: email, value: responseOptions.display_email };
          dispatch(otherOptionsActionCreator(responseEmail));
          dispatch(
            serverStateCreator({
              selectedAddressOption,
              jobTitle: responseOptions.display_diploma_title,
              telephoneSingle: responseOptions.display_phone,
              emailSingle: responseOptions.display_email,
              mobileSingle: responseOptions.display_mobile,
            })
          );
        }
        if (isUserCoporate) {
          if (detailsRef.current && textAreaRef.current) {
            const textArea = textAreaRef.current.querySelector(".bx--text-area");
            const detailsAreaHeight = parseFloat(window.getComputedStyle(detailsRef.current).height.replace("px", ""));
            adjustTextAreaHeight(textArea, detailsAreaHeight);
          }
        }
        if (isUserSingle) {
          const membershipBlock = membershipRef.current;
          const membershipBlockHeight = parseFloat(window.getComputedStyle(membershipBlock).height.replace("px", ""));
          const PADDING_TOTAL = 32;
          const detailsAreaHeight = parseFloat(window.getComputedStyle(detailsRef.current).height.replace("px", "")) - PADDING_TOTAL;
          if (membershipBlockHeight < detailsAreaHeight) {
            // adjustTextAreaHeight(membershipRef.current, detailsAreaHeight)
            membershipRef.current.style.minHeight = detailsAreaHeight + "px";
          }
        }
      };
      if (isUserCoporate) {
        webPlatformAdapter.getCorporateOptions(language, handleResponse);
      } else if (isUserSingle) {
        webPlatformAdapter.getSingleOptions(language, handleResponse);
      }
      dispatch(shouldFetchActionCreator(false));
    }
  }, [
    dispatch,
    fullAddress,
    cityOnly,
    noAddress,
    isUserCoporate,
    isUserSingle,
    jobTitle,
    telephone,
    email,
    website,
    state.shouldFetchData,
    mobileSingle,
    language,
  ]);

  const groupByKey = (object, key) => {
    if (!object) {
      return undefined;
    }

    let groupObject = object.reduce((acc, e) => {
      const groupKey = e[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [e];
      } else {
        acc[groupKey].push(e);
      }
      return acc;
    }, {});

    return groupObject;
  };

  const adjustTextAreaHeight = (textArea, targetHeight) => (textArea.style.height = targetHeight + "px");

  const renderControlInput = (option) => {
    if (option === "address") {
      let items = Object.values(state.addressOptions);
      return (
        <RadioButtons
          name={option}
          items={items}
          selectedValue={state.selectedAddressOptions}
          onChange={(value) => dispatch(addressOptionsActionCreator(value))}
        />
      );
    }
    if (option === "other") {
      let items = Object.entries(state.otherOptions);
      return items.map(([key, value]) => {
        return (
          <Checkbox
            key={value.label}
            id={value.label}
            label={value.label}
            value={value.value}
            onChange={(e, selectedValue) => dispatch(otherOptionsActionCreator({ key, label: value.label, value: selectedValue }))}
          />
        );
      });
    }
    return null;
  };

  const renderOptionsBlock = (option) => {
    return (
      <FormFieldsColumn key={option}>
        <div className="input-controls">
          <InfoBlock loose>
            <span className={"label"}>{translate(`account.customer-data.sia-directory.${option}-options`)}</span>
            <div>{renderControlInput(option)}</div>
          </InfoBlock>
        </div>
      </FormFieldsColumn>
    );
  };

  const renderCardBlock = () => {
    let actions = [];
    if (isUserSingle) {
      if (state.personalInfo.linkedin) {
        actions.push(
          <Link key={"Linkedin"} target={state.personalInfo.linkedin}>
            <LogoLinkedin32 />
          </Link>
        );
      }
      if (state.personalInfo.xing) {
        actions.push(
          <Link key={"Xing"} target={state.personalInfo.xing}>
            <LogoXing32 />
          </Link>
        );
      }
    }
    const icons = isUserSingle && actions.length > 0 ? actions : null;

    const stateProperyIsNotFalsy = (propertyName) => {
      switch (propertyName) {
        case "telephone":
        case "telephoneSingle":
          return state.personalInfo.phone;
        case "email":
        case "emailSingle":
          return state.personalInfo.email;
        case "mobileSingle":
          return state.personalInfo.mobile;
        case "website":
          return state.personalInfo.website;
        default:
          return true;
      }
    };
    return (
      <InfoBlock actionIcons={icons}>
        {
          <React.Fragment key={"name"}>
            <span className="label">{translate("account.customer-data.sia-directory.card.name")}</span>

            <p>{state.personalInfo.name}</p>
          </React.Fragment>
        }

        {state.selectedAddressOptions !== noAddress ? (
          <React.Fragment key={"address"}>
            <span className="label">{translate("account.customer-data.sia-directory.card.address")}</span>
            {state.selectedAddressOptions === cityOnly ? (
              <p>{state.personalInfo.city}</p>
            ) : (
              <p>
                {state.personalInfo.addressLines.map((line) => (
                  <React.Fragment key={line[0]}>
                    <span>{line[1]}</span>
                    <br />
                  </React.Fragment>
                ))}
              </p>
            )}
          </React.Fragment>
        ) : null}

        {isUserSingle && state.otherOptions.jobTitle.value && (
          <React.Fragment key={"jobTitle"}>
            <span className="label">{translate("account.customer-data.sia-directory.single.options.job-title")}</span>
            <p>{state.personalInfo.diplomaTitle}</p>
          </React.Fragment>
        )}

        {Object.entries(state.otherOptions).some(([propertyName, propertyValue]) => {
          return propertyValue.label === jobTitle ? false : propertyValue.value && stateProperyIsNotFalsy(propertyName);
        }) && (
          <React.Fragment key={"contact"}>
            <span className="label">{translate("account.customer-data.sia-directory.card.contact")}</span>
            <>
              {(state.otherOptions?.telephone?.value || state.otherOptions?.telephoneSingle?.value) && state.personalInfo.phone && (
                <span>T {phoneFormatter.format(state.personalInfo.phone)}</span>
              )}

              {isUserCoporate && (
                <p key={"corporate-user"} className="corporate-user">
                  {state.otherOptions.email.value && state.personalInfo.email && (
                    <React.Fragment key={"email"}>
                      <Link target={state.personalInfo.email}>{state.personalInfo.email}</Link>
                      <br />
                    </React.Fragment>
                  )}
                  {state.otherOptions.website.value && state.personalInfo.website && (
                    <React.Fragment key={"website"}>
                      <Link target={state.personalInfo.website}>{state.personalInfo.website}</Link>
                      <br />
                    </React.Fragment>
                  )}
                </p>
              )}

              {isUserSingle && (
                <p key={"single-user"} className="single-user">
                  {state.otherOptions.mobileSingle.value && state.personalInfo.mobile && (
                    <React.Fragment key={"mobile"}>
                      <span target={state.personalInfo.mobile}>M {phoneFormatter.format(state.personalInfo.mobile)}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {state.otherOptions.emailSingle.value && state.personalInfo.email && (
                    <React.Fragment key={"email"}>
                      <Link target={state.personalInfo.email}>{state.personalInfo.email}</Link>
                      <br />
                    </React.Fragment>
                  )}
                </p>
              )}
            </>
          </React.Fragment>
        )}

        <div></div>
      </InfoBlock>
    );
  };

  const rednerMembershipBlock = () => {
    if (isUserCoporate) {
      return (
        <div className="text-area-wrapper" ref={textAreaRef}>
          <TextArea
            id={"furtherInfo"}
            label={translate("account.customer-data.sia-directory.corporate.further-info")}
            value={state.furtherInfo}
            onChange={(e, value) => dispatch(furtherInfoActionCreator(value))}
            limit={10000}
          />
        </div>
      );
    }

    if (isUserSingle) {
      const isArrayNotEmpty = (arr) => arr.length > 0;
      const professionalGroups = Object.entries(state.personalInfo.memberships?.professional_groups || []).filter(([_, group]) => group.member);
      const ismemberOfAnyGroup = isArrayNotEmpty(professionalGroups);

      const sections = state.personalInfo.memberships?.sections || [];
      const isMemberOfAnySections = isArrayNotEmpty(sections);

      const commissions = state.personalInfo.memberships?.commissions || [];
      const isMemberOfAnyCommissions = isArrayNotEmpty(commissions);
      return (
        <div className="membership">
          <h3>{translate("account.customer-data.sia-directory.single.memberships")}</h3>
          <InfoBlock>
            <div ref={membershipRef}>
              {ismemberOfAnyGroup && (
                <>
                  <span className="label">{translate("account.customer-data.sia-directory.single.membership.professional-groups")}</span>
                  {professionalGroups.map((group) => {
                    return (
                      <p key={group[0]}>
                        <span key={group}>{group[0]}</span>
                        <br />
                        <span key={group[1].member[0].member_type_name}>- {group[1].member[0].member_type_name}</span>
                        <br />
                        {group[1].council && (
                          <React.Fragment key={"council"}>
                            <span>
                              - {group[1].council[0].group_name}, {group[1].council[0].role_name}
                            </span>
                            <br />
                          </React.Fragment>
                        )}
                        {group[1].delegate && (
                          <React.Fragment key={"delegate"}>
                            <span>- {group[1].delegate[0].role_name} </span>
                            <br />
                          </React.Fragment>
                        )}
                        {group[1].committee && (
                          <React.Fragment key={"committee"}>
                            <span>- {group[1].committee[0].role_name}</span>
                            <br />
                          </React.Fragment>
                        )}
                        {group[1].commission && (
                          <React.Fragment key={"commission"}>
                            <span>- {group[1].commission[0].role_name}</span>
                            <br />
                          </React.Fragment>
                        )}
                      </p>
                    );
                  })}
                </>
              )}

              {isMemberOfAnySections && (
                <>
                  <span className="label">{state.personalInfo.memberships.sections[0].group_name}</span>
                  <p>
                    {state.personalInfo.memberships.sections.map((section) => (
                      <React.Fragment key={section.section_code}>
                        <span>
                          {section.section_name}, {section.member_type_name}
                        </span>
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}

              {isMemberOfAnyCommissions && (
                <>
                  <span className="label">{state.personalInfo.memberships.commissions[0].group_name}</span>
                  <p>
                    {state.personalInfo.memberships.commissions.map((commission) => (
                      <React.Fragment key={commission.commission_code}>
                        <span>{commission.commission_code}</span>
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </>
              )}
            </div>
          </InfoBlock>
        </div>
      );
    }
  };

  const renderDetailsBlock = () => {
    return (
      <>
        <h3 className="productive-heading-03">{translate("account.customer-data.sia-directory.details.label")}</h3>
        <div className="details-block-wrapper" ref={detailsRef}>
          <InfoBlock>
            {state.workBranches.length > 0 && state.workBranches.every((e) => e) && (
              <>
                <span className="label tags-label">{state.personalInfo.branchLabel}</span>
                <div className="tags">
                  <TagsGroup items={state.workBranches.map((tag) => ({ label: tag, type: "tertiary" }))} />
                </div>
              </>
            )}
            {state.workSpecialisations.length > 0 && state.workSpecialisations.every((e) => e) && (
              <>
                <span className="label tags-label">{state.personalInfo.specialisationLabel}</span>
                <div className="tags">
                  <TagsGroup items={state.workSpecialisations.map((tag) => ({ label: tag, type: "tertiary" }))} />
                </div>
              </>
            )}
            {isUserCoporate ? (
              <div className="tags">
                {Object.entries(state.personalInfo.individualMemeberships).map(([groupName, members]) => {
                  return (
                    <React.Fragment key={groupName}>
                      <span className="label">{groupName}</span>
                      {members.map((member, i) => (
                        <p key={i}>
                          <React.Fragment>
                            <span>
                              {member.lastname} {member.firstname}, {member.zip}, {member.city}{" "}
                            </span>
                            <br />
                          </React.Fragment>
                        </p>
                      ))}
                    </React.Fragment>
                  );
                })}
              </div>
            ) : null}
          </InfoBlock>
        </div>
      </>
    );
  };

  const handleSubmit = () => {
    const METHOD = "PUT";
    const CORPORATE_END_POINT = "account/corporate/services/directory/options";
    const SINGLE_END_POINT = "account/single/services/directory/options";

    const reauestBodyMap = new Map();

    const getStateAddressOption = () => {
      switch (state.selectedAddressOptions) {
        case fullAddress:
          return "full";
        case cityOnly:
          return "city";
        case noAddress:
          return "none";
        default:
          return null;
      }
    };

    const onSucess = () => dispatch(shouldFetchActionCreator(true));

    reauestBodyMap.set("display_address_option", getStateAddressOption());
    if (isUserCoporate) {
      reauestBodyMap.set("display_phone", state.otherOptions.telephone.value);
      reauestBodyMap.set("display_email", state.otherOptions.email.value);
      reauestBodyMap.set("display_website", state.otherOptions.website.value);
      reauestBodyMap.set("self_description", state.furtherInfo);
      const requestBody = Object.fromEntries(reauestBodyMap.entries());

      erpAdapter.fetchWithDelay(METHOD, CORPORATE_END_POINT, requestBody, onSucess, null, 4000);
    } else if (isUserSingle) {
      reauestBodyMap.set("display_phone", state.otherOptions.telephoneSingle.value);
      reauestBodyMap.set("display_email", state.otherOptions.emailSingle.value);
      reauestBodyMap.set("display_diploma_title", state.otherOptions.jobTitle.value);
      reauestBodyMap.set("display_mobile", state.otherOptions.mobileSingle.value);
      const requestBody = Object.fromEntries(reauestBodyMap.entries());

      erpAdapter.fetchWithDelay(METHOD, SINGLE_END_POINT, requestBody, onSucess, null, 4000);
    }
  };

  const resetToServerState = () => {
    const currentSelectedAddressOptionOnServer = state.serverState.selectedAddressOption;
    dispatch(addressOptionsActionCreator(currentSelectedAddressOptionOnServer));

    if (isUserCoporate) {
      const currentSelectedTelephoneOnServer = state.serverState.telephone;
      dispatch(otherOptionsActionCreator({ key: "telephone", label: telephone, value: currentSelectedTelephoneOnServer }));
      const currentSelectedEmailOnServer = state.serverState.email;
      dispatch(otherOptionsActionCreator({ key: "email", label: email, value: currentSelectedEmailOnServer }));
      const currentSelectedWebsiteOnServer = state.serverState.website;
      dispatch(otherOptionsActionCreator({ key: "website", label: website, value: currentSelectedWebsiteOnServer }));
      const currentFurtherInfoOnServer = state.serverState.furtherInfo;
      dispatch(furtherInfoActionCreator(currentFurtherInfoOnServer));
    }
    if (isUserSingle) {
      const currentSelectedJobTitleOnServer = state.serverState.jobTitle;
      dispatch(otherOptionsActionCreator({ key: "jobTitle", label: jobTitle, value: currentSelectedJobTitleOnServer }));
      const currentSelectedTelephoneOnServer = state.serverState.telephoneSingle;
      dispatch(otherOptionsActionCreator({ key: "telephoneSingle", label: telephoneSingle, value: currentSelectedTelephoneOnServer }));
      const currentSelectedEmailOnServer = state.serverState.emailSingle;
      dispatch(otherOptionsActionCreator({ key: "emailSingle", label: emailSingle, value: currentSelectedEmailOnServer }));
      const currentSelectedMobileOnServer = state.serverState.mobileSingle;
      dispatch(otherOptionsActionCreator({ key: "mobileSingle", label: mobileSingle, value: currentSelectedMobileOnServer }));
    }
  };

  const renderContent = () => {
    const options = ["address", "other"];

    return (
      <div className="manage-directory">
        <Form
          primaryButtonLabel={translate("form.save")}
          secondaryButtonLabel={translate("form.cancel")}
          onClickSecondaryButton={resetToServerState}
          onClickPrimaryButton={handleSubmit}
        >
          <div className="bx--row upper-row">
            <FormFieldsColumn>
              <div className="bx--row">{options.map((option) => renderOptionsBlock(option))}</div>
            </FormFieldsColumn>

            <FormFieldsColumn>{renderCardBlock()}</FormFieldsColumn>
          </div>

          <div className="bx--row lower-row">
            <FormFieldsColumn>{rednerMembershipBlock()}</FormFieldsColumn>

            <FormFieldsColumn>{renderDetailsBlock()}</FormFieldsColumn>
          </div>
        </Form>
      </div>
    );
  };

  const renderDescription = () => {
    if (!user) {
      return null;
    }
    if (isUserCoporate) {
      return translate("account.customer-data.sia-directory.corporate.description");
    } else if (isUserSingle) return translate("account.customer-data.sia-directory.single.description");
    return null;
  };

  return (
    <Page contentNavigation={<UserContentNavigation />} title={"account.name"}>
      <AppContent>
        <h1>
          <BackButton onClick={() => history.push(pathFactory.create("account/customer-data"))} />
          {title}
        </h1>
        <p>{renderDescription()}</p>
      </AppContent>
      <Content centeredTitle={true}>{renderContent()}</Content>
    </Page>
  );
}

export default ManageDirectory;
