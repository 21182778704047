import React from "react";
import { ContentNavigation } from "@sia/style-guide/dist/components";
import { useTranslator } from "common/Translation/Translator";
export const DirectoryContentNavigation = ({
  selectedTabIndex,
  setSelectedTabIndex,
  corporateMembersLength,
  singleMembersLength,
  juniorMembersLength,
  honoraryMembersLength,
  seniorMembersLength,
  studentMembersLength,
}) => {
  const translate = useTranslator();

  const routerItems = [
    {
      label: `${translate("directory.content-nav.corporates")} (${corporateMembersLength})`,
      index: 0,
    },
    {
      label: `${translate("directory.content-nav.single")} (${singleMembersLength})`,
      index: 1,
    },
    {
      label: `${translate("directory.content-nav.junior")} (${juniorMembersLength})`,
      index: 2,
    },
    {
      label: `${translate("directory.content-nav.honorary")} (${honoraryMembersLength})`,
      index: 3,
    },
    {
      label: `${translate("directory.content-nav.senior")} (${seniorMembersLength})`,
      index: 4,
    },
    {
      label: `${translate("directory.content-nav.student")} (${studentMembersLength})`,
      index: 5,
    },
  ];

  const contentNavigationItems = routerItems.map((routerItem) => {
    return {
      label: routerItem.label,
      onClick: () => setSelectedTabIndex(routerItem.index),
    };
  });
  const selectedItemIndex = routerItems.findIndex((routerItem) => routerItem.index === selectedTabIndex);
  return <ContentNavigation items={contentNavigationItems} preselectedItemIndex={selectedItemIndex} />;
};

export default DirectoryContentNavigation;
